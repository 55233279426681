/* Pages */
import LandingPage from './LandingPage';
import NotFoundPage from './NotFoundPage';
import ConteoCriticidadPage, {
  ReporteInconsistenciaFechaLogComponent,
  ReporteConteoCriticidadLogComponent,
  ReporteCategoriaConteoMontoComponent,
  ReporteCategoriaConteoCantidadComponent,
  ReporteZonaConteoMontoComponent,
  ReporteZonaConteoCantidadComponent,
  ReporteSKUMontoComponent,
  ReporteSKUCantidadComponent,
  ReporteVencimientoConteoMontoComponent,
  ReporteVencimientoConteoCantidadComponent,
  ReporteInventarioCriticidadComponent
} from './ConteoCriticidad';
import ConteoEnvasesPage from './ConteoEnvases';
import ConteoPalletPage from './ConteoPallet';
import WelcomePage from './WelcomePage';
import AnonimousPage from './AnonimousPage';
import ObserverPage from './ObserverPage';
import EnvasesAndPalletCompliancePage from './EnvasesAndPalletCompliancePage';
import {
  ZonasOperadoresCRUD,
  SkuCRUD,
  PerfilEnvejecimientoCRUD,
  DiasVentaCRUD
} from '../components/GeneratedComponents';
import {
  UsuarioCRUDMaintainer,
  TipoUsuarioCRUDMaintainer,
  EstadoDocumentoCRUDMaintainer,
  SkuFactorCRUDMaintainer,
  CentroInventarioCRUDMaintainer,
  ActivosCRUDMaintainer,
  TipoActivosCRUDMaintainer,
  NomenclaturasCRUDMaintainer,
  RelacionEnvaseCasilleroCRUDMaintainer,
  ComentarioMaintainer,
  MaterialNomenclaturaMaintainer,
  ItemSociedadCRUDMaintainer,
  ItemCRUDMaintainer,
  SociedadCRUDMaintainer
} from './Mantenedores';
import { TrazabilidadConnected } from './Trazabilidad';
import { TrazabilidadAdminConnected } from './TrazabilidadAdmin';
import { TrazabilidadFaltanteConnected } from './TrazabilidadFaltante';
import { TrazabilidadMermaConnected } from './TrazabilidadMerma';
import TrazabilidadDashboard from './TrazabilidadDashboard';

/* Pages */

const Pages = {
  LandingPage,
  NotFoundPage,
  ConteoCriticidadPage,
  ConteoEnvasesPage,
  ConteoPalletPage,
  WelcomePage,
  ObserverPage,
  AnonimousPage,
  TrazabilidadConnected,
  TrazabilidadAdminConnected,
  TrazabilidadFaltanteConnected,
  TrazabilidadMermaConnected,
  TrazabilidadDashboard
};

/* Mantenedores */

export const Mantenedores = {
  UsuarioCRUDMaintainer,
  ActivosCRUDMaintainer,
  TipoUsuarioCRUDMaintainer,
  TipoActivosCRUDMaintainer,
  SkuFactorCRUDMaintainer,
  SkuCRUD,
  RelacionEnvaseCasilleroCRUDMaintainer,
  PerfilEnvejecimientoCRUD,
  NomenclaturasCRUDMaintainer,
  DiasVentaCRUD,
  EstadoDocumentoCRUDMaintainer,
  CentroInventarioCRUDMaintainer,
  ZonasOperadoresCRUD,
  ComentarioMaintainer,
  MaterialNomenclaturaMaintainer,
  ItemSociedadCRUDMaintainer,
  ItemCRUDMaintainer,
  SociedadCRUDMaintainer
};
/* Mantenedores */

/* Reportes */

export const Reportes = {
  conteosCriticidad: {
    ReporteInconsistenciaFechaLogComponent,
    ReporteConteoCriticidadLogComponent
  },
  generales: {
    ReporteCategoriaConteoMontoComponent,
    ReporteCategoriaConteoCantidadComponent,
    ReporteZonaConteoMontoComponent,
    ReporteZonaConteoCantidadComponent,
    ReporteSKUMontoComponent,
    ReporteSKUCantidadComponent,
    ReporteVencimientoConteoMontoComponent,
    ReporteVencimientoConteoCantidadComponent,
    ReporteInventarioCriticidadComponent,
    EnvasesAndPalletCompliancePage
  }
};

export default Pages;
