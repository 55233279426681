import React, {
  FunctionComponent,
  ReactNode,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
  useMemo
} from 'react';
import { connect } from 'react-redux';
import { OCA, Documento, SimpleObject, TipoCobro, stateRules, EstadoOCA, Cobro } from '../types';
import { getUncatchEndpointData } from '../utils/request';
import { addObjectToStorage, removeFromStorage } from '../utils/storage';
import { Fields, WebEntityName } from '../entities/types';
import { popAlert, PopAlertType } from './PopAlert';
import {
  refreshConteoCriticidadLog,
  refreshReporteInconsistenciaFechaLog,
  cleanSelectedEntities,
  agregarOca,
  dateFilterEntity,
  objetarOcaPorOrigen,
  objetarOcaPorEett,
  decisionEnDiscusionOCA,
  crearCobro,
  contraResponderOcaPorDestino,
  reabrirOca,
  agregarIdCobroCcu,
  agregarCartaCobranza
} from '../actions/middleActions';
import GenericIcons from './generics/GenericIcons';
import { Alert, AlertColor, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { WebEntity } from '../new-table/entities/types';
import * as newE from '../new-table/entities';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateView } from '@mui/x-date-pickers';
import { toast } from 'react-toastify';
import moment from 'moment';
import ModalComponent from '../new-table/components/ModalComponent';
import { FileUploader } from 'react-drag-drop-files';
import InputField from './InputField';
import { dateFormatFn } from '../utils/utils';
import { hasTimePassed } from '../new-table/utils';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RestoreIcon from '@mui/icons-material/Restore';
import Swal from 'sweetalert2';

type ButtonParams = {
  action: (params?: any, webEntity?: any) => Promise<any>;
  selected: any[];
};

type Props = {
  action: (params?: any) => Promise<any>;
  onClick: (buttonParams: ButtonParams) => Promise<void>;
  children: ReactNode;
  selected?: any[];
  style?: CSSProperties;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
};

const SecondaryButton: FunctionComponent<Props> = ({
  action,
  onClick,
  selected = [],
  children,
  style,
  variant,
  color,
  disabled
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick({ action, selected });
    setLoading(false);
  };

  return (
    <Button
      onClick={handleOnClick}
      disabled={loading || disabled}
      style={style}
      variant={variant}
      color={color}>
      {children}
    </Button>
  );
};

const connectedSecondaryButton = (action: any) => connect(null, { action })(SecondaryButton);

/* const genericOnClick = async (buttonParams: ButtonParams) => {
 *   const { action, selected } = buttonParams;
 *   await action(selected.map((s: any) => s._id));
 * }; */

/* export const exampleButton = ({
 *   selected,
 *   refreshStatus
 * }: {
 *   selected: TypeOfSelected[];
 *   refreshStatus: TypeOfStatus;
 * }) => {
 *   const ConnectedBtn = connectedSecondaryButton(actionToPerform(refreshStatus));
 *
 *   return (
 *     <ConnectedBtn
 *       onClick={genericOnClick}
 *       selected={selected}
 *       btnProps={{ basic: true, color: 'orange', inverted: true }}>
 *       <Icon name='undo' />
 *       Deshacer
 *     </ConnectedBtn>
 *   );
 * }; */

export const RefreshConteoCriticidadLogButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshConteoCriticidadLog);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} variant='contained'>
      <RefreshIcon />
      Refrescar
    </ConnectedBtn>
  );
};

export const RefreshReporteInconsistenciaFechaLogButton = () => {
  const ConnectedBtn = connectedSecondaryButton(refreshReporteInconsistenciaFechaLog);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    await action();
  };

  return (
    <ConnectedBtn onClick={onClick} variant='contained'>
      <RefreshIcon />
      Refrescar
    </ConnectedBtn>
  );
};

export const DuplicateDocMultiForm = ({
  selected,
  endpoint,
  endpointNameMultiInput,
  fields,
  entityName
}: {
  selected: Array<Documento>;
  endpoint: string;
  endpointNameMultiInput: string;
  fields: Fields<any>[];
  entityName: WebEntityName;
}) => {
  const documentoId = selected.map((item) => item._id);
  const ConnectedBtn = connectedSecondaryButton(cleanSelectedEntities);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    if (documentoId.length > 1) {
      popAlert({
        type: PopAlertType.ERROR,
        title: `Accion no permitida`,
        details: `No se permite duplicar mas de un conteo`
      });
      return;
    }

    const documentData = await getUncatchEndpointData({
      endpoint: `${endpointNameMultiInput}/getDocumentById`,
      query: {
        documentoId: documentoId
      }
    });

    const conteoData = await getUncatchEndpointData({
      endpoint: `${endpoint}/getDataByDocumentId`,
      query: {
        documentoId: documentoId
      }
    });

    const centro = await getUncatchEndpointData({
      endpoint: `centroInventario/nombreCentro`,
      query: {
        zonaDeTransporte: conteoData[0].bodega
      }
    });

    documentData.zonaDeTransporte = centro.nombreCentro;

    removeFromStorage(endpointNameMultiInput);
    const multiInputFields = [];
    const state: Record<string, string> = {};
    const headerFields = fields.filter((f: any) => !f.multiInput?.multiField);
    const multiFields = fields.filter((f: any) => f.multiInput?.multiField);

    headerFields
      .filter((field) => field.multiInput?.isDuplicable)
      .map((field) => {
        state[field.selector] = documentData[field.selector];
      });

    for (let i = 0; i < conteoData.length; i++) {
      multiInputFields.push(multiFields);

      multiFields
        .filter((field) => field.multiInput?.isDuplicable)
        .map((field) => {
          state[`${field.selector}_${i}`] = conteoData[i][field.selector];
        });
    }
    await addObjectToStorage(endpointNameMultiInput, {
      multiInputFields: multiInputFields,
      state: state
    });

    await action(entityName);

    const btn = document.getElementById(endpointNameMultiInput) as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  };

  return (
    <ConnectedBtn onClick={onClick} variant='contained'>
      <GenericIcons icon='duplicateWhite' />
      Duplicar
    </ConnectedBtn>
  );
};

const transformFilesToString = async (
  files: File[] | null
): Promise<{ name: string; type: string; content: string }[]> => {
  if (!files) return [];

  const filePromises = Array.from(files).map((file) => {
    return new Promise<{ name: string; type: string; content: string }>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve({
          name: file.name,
          type: file.type,
          content: reader.result as string
        });
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  });

  return await Promise.all(filePromises);
};

export const AgregarCobro: FunctionComponent<{
  tipoCobro: TipoCobro;
  data: {
    cdOrigen: string;
    cdDestino: string;
    eett: string;
    oca: string;
    patente: string;
    guia: string;
    dateOca: moment.Moment | null;
    detalle: string;
    causal: string;
    files: File[] | null;
    skuData: Array<{
      sku: string;
      cantidad: string;
    }>;
  };
  disabled: boolean;
  toggleModal: () => void;
  setLoadingOutward: Dispatch<SetStateAction<boolean>>;
  webEntity: WebEntity<OCA>;
}> = ({ tipoCobro, data, disabled, toggleModal, setLoadingOutward, webEntity }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [faltaInfo, setFaltaInfo] = useState<boolean>(false);

  useEffect(() => setLoadingOutward(loading), [setLoadingOutward, loading]);

  const validateData = useCallback(() => {
    if (
      !data.cdOrigen ||
      !data.cdDestino ||
      !data.eett ||
      !data.oca ||
      !data.patente ||
      data.patente.length !== 6 ||
      !data.guia ||
      !data.dateOca ||
      !data.detalle ||
      (tipoCobro === TipoCobro.merma && !data.causal) ||
      !data.files ||
      data.files.length === 0 ||
      !data.skuData ||
      data.skuData.length === 0
    ) {
      return true;
    }

    const isSkuDataValid = data.skuData.every(
      (skuItem) => skuItem.sku && Number(skuItem.cantidad) > 0
    );

    if (!isSkuDataValid) {
      return true;
    }

    return false;
  }, [data]);

  useEffect(() => setFaltaInfo(validateData()), [validateData]);

  const ConnectedBtn = connectedSecondaryButton(agregarOca);

  const onClick = async (buttonParams: ButtonParams) => {
    setLoading(true);
    if (!tipoCobro) {
      alert('No se recibio el tipo de cobro. Contacte al administrador del sistema');
      setLoading(false);
      return;
    }

    const { action } = buttonParams;

    const processedFiles = await transformFilesToString(data.files);

    const res = await action(
      {
        ...data,
        files: processedFiles,
        dateOca: data.dateOca?.format('YYYY-MM-DD'),
        tipoCobro
      },
      webEntity
    );

    if (res) {
      toggleModal();
    }
    setLoading(false);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <ConnectedBtn onClick={onClick} variant='contained' disabled={loading || faltaInfo || disabled}>
      Finalizar
    </ConnectedBtn>
  );
};

export const DateFilter: FunctionComponent<{
  webEntity: WebEntity<any>;
  viewsDate: DateView[];
  additionalParams?: SimpleObject;
}> = ({ webEntity, viewsDate, additionalParams }) => {
  const ConnectedBtn = connectedSecondaryButton(dateFilterEntity);
  const [isFiltered, setIsFiltered] = useState<SimpleObject | null>(null);
  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;

    const date = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;

    const dateFormattedMsj = selectedDate
      ? viewsDate.includes('month')
        ? selectedDate.format('MM-YYYY')
        : selectedDate.format('YYYY')
      : '';

    toast.promise(
      async () => {
        setLoading(true);
        const res = await action({ date, additionalParams }, webEntity);
        setLoading(false);
        setIsFiltered(res);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Filtrado! ${dateFormattedMsj ? `Fecha: ${dateFormattedMsj}` : ''}`,
        error: 'Ocurrió un error al realizar el filtro'
      }
    );
  };

  const onClickRemoveFilters = async (buttonParams: ButtonParams) => {
    const { action } = buttonParams;
    toast.promise(
      async () => {
        const currentDate = moment(new Date());
        setLoading(true);
        const res = await action(
          { date: currentDate.format('YYYY-MM-DD'), additionalParams },
          webEntity
        );
        setLoading(false);
        setIsFiltered(null);
        setSelectedDate(null);
        return res;
      },
      {
        pending: 'Cargando...',
        success: `Se quitó el filtro!`,
        error: 'Ocurrió un error al quitar el filtro'
      }
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
      {isFiltered && (
        <ConnectedBtn
          onClick={onClickRemoveFilters}
          disabled={!isFiltered || loading}
          color='inherit'
          style={{ padding: '12px' }}>
          <FilterAltOffIcon color='action' fontSize='large' />
        </ConnectedBtn>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='Es'>
        <DatePicker
          views={viewsDate}
          openTo='year'
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
      </LocalizationProvider>

      <ConnectedBtn
        onClick={onClick}
        disabled={!selectedDate || loading}
        color='inherit'
        style={{ padding: '12px' }}>
        <SearchIcon fontSize='large' />
      </ConnectedBtn>
    </div>
  );
};

export const ObjetarOcaPorOrigen: FunctionComponent<{ rowData: OCA; tipoCobro: TipoCobro }> = ({
  rowData,
  tipoCobro
}) => {
  const ConnectedBtn = connectedSecondaryButton(objetarOcaPorOrigen);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [files, setFiles] = useState<File[] | null>(null);
  const [error, setError] = useState<{ severity: AlertColor; msg: ReactNode }>({
    severity: 'warning',
    msg: ''
  });
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaIngresada, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.ingresada].reopenedDays
        : stateRules[EstadoOCA.ingresada].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(dateFormatFn(rowData.fechaOcaIngresada, 'YYYY-MM-DD HH:mm:ss', true)),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.ingresada].reopenedDays
            : stateRules[EstadoOCA.ingresada].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaIngresada]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteIngresada : newE.OcaMermaIngresada;

  const onClickNoObjetar = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas NO objetar?',
      text: 'La OCA se cerrará',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({ ocaId: rowData._id, objetar: false, tipoCobro }, entity);
            setLoading(false);
            if (res) {
              setFiles(null);
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickObjetar = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Confirmas la objeción?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        const processedFiles = await transformFilesToString(files);

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, objetar: true, tipoCobro, comentario, files: processedFiles },
              entity
            );
            setLoading(false);
            if (res) {
              setFiles(null);
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const allowedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'];

  const handleChangeFiles = (newFiles: File[] | null) => {
    setError({ severity: 'warning', msg: '' });
    if (!newFiles) {
      return;
    }

    if ((files && files.length + newFiles.length > 2) || newFiles.length > 2) {
      setError({ severity: 'error', msg: `Solo puedes subir un máximo de 2 archivos.` });
      return;
    }
    for (const file of newFiles) {
      const fileType = file.type.split('/')[1];
      if (!allowedFileTypes.includes(fileType)) {
        setError({ severity: 'error', msg: `Formato de archivo no soportado (${fileType}).` });
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        if (fileType === 'pdf') {
          setError({
            severity: 'error',
            msg: `El PDF ${file.name} no puede exceder el tamaño máximo de 2 MB.`
          });
          return;
        }
        setError({
          severity: 'warning',
          msg: (
            <>
              La imagen {file.name} excede el tamaño máximo de 2 MB, se cargará pero en{' '}
              <b>menor calidad</b>.
            </>
          )
        });
      }
    }

    setFiles((prevFiles) => {
      if (!prevFiles) return [...newFiles];
      return [...prevFiles, ...newFiles];
    });
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => {
      if (prevFiles) {
        const res = prevFiles.filter((_, i) => i !== index);
        if (res.length === 0) return null;
        return res;
      }
      return null;
    });
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <div style={{ display: 'flex', gap: '5px' }}>
      <ModalComponent
        modalTitle='Objeción'
        disabledModalBtn={loading}
        toggleModal={handleToggleModal}
        isModalOpen={open}
        body={
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <InputField
              label='Comentario'
              value={comentario}
              type='text'
              handleInputChange={(e) => {
                setComentario(e.target.value);
              }}
              maxLength={300}
              disabled={loading}
              fullWidth
              required
            />
            <div className='trazabilidad-file-uploader-container'>
              <FileUploader
                classes='trazabilidad-file-uploader'
                handleChange={handleChangeFiles}
                name='file'
                label='Cargue o arrastre el archivo aquí'
                hoverTitle='Soltar aquí'
                types={allowedFileTypes}
                fileOrFiles={files}
                disabled={loading}
                multiple={true}
                required
                onTypeError={(err: any) => {
                  if (err === 'File type is not supported') {
                    setError({ severity: 'error', msg: `Formato de archivo no soportado.` });
                    setFiles(null);
                  }
                }}
              />
              {error.msg && <Alert severity={error.severity}>{error.msg}</Alert>}
              {files && files.length > 0 && (
                <div className='trazabilidad-file-uploader-file-list-container'>
                  <h3 className='trazabilidad-file-uploader-file-list-title'>Archivos cargados:</h3>

                  {files.map((file, idx) => (
                    <p key={idx} className='trazabilidad-file-uploader-file-list-item'>
                      <span
                        className='trazabilidad-file-uploader-file-list-item-remove'
                        onClick={() => handleRemoveFile(idx)}>
                        X
                      </span>{' '}
                      {file.name}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        }
        actionBtn={
          <ConnectedBtn
            onClick={onClickObjetar}
            disabled={loading || !comentario || !files}
            color='success'
            variant='contained'
            style={{ gap: '2px' }}>
            Objetar
          </ConnectedBtn>
        }
        btnLabel='Si'
        btnVariant='contained'
        btnColor='info'
        customStyles={{
          modalContainer: {
            height: 'auto'
          }
        }}
      />

      <ConnectedBtn
        onClick={onClickNoObjetar}
        disabled={loading}
        color='error'
        variant='contained'
        style={{ gap: '2px' }}>
        No
      </ConnectedBtn>
    </div>
  );
};

export const ObjetarOcaPorEett: FunctionComponent<{ rowData: OCA; tipoCobro: TipoCobro }> = ({
  rowData,
  tipoCobro
}) => {
  const ConnectedBtn = connectedSecondaryButton(objetarOcaPorEett);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<{ severity: AlertColor; msg: ReactNode }>({
    severity: 'warning',
    msg: ''
  });
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaObjetadaOrigen, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.objetadaOrigen].reopenedDays
        : stateRules[EstadoOCA.objetadaOrigen].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(dateFormatFn(rowData.fechaOcaObjetadaOrigen, 'YYYY-MM-DD HH:mm:ss', true)),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.objetadaOrigen].reopenedDays
            : stateRules[EstadoOCA.objetadaOrigen].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaObjetadaOrigen]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteObjetadaOrigen : newE.OcaMermaObjetadaOrigen;

  const onClickNo = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas NO objetar?',
      text: 'La OCA pasará a ser Cobrable',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({ ocaId: rowData._id, objetar: false, tipoCobro }, entity);
            setLoading(false);
            if (res) {
              setComentario('');
              setFile(null);
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickSi = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Confirmas la objeción?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        const processedFiles = await transformFilesToString(file ? [file] : null);

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              {
                ocaId: rowData._id,
                objetar: true,
                tipoCobro,
                comentario,
                file: processedFiles
              },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              setFile(null);
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const allowedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'];

  const handleChangeFiles = (newFile: File | null) => {
    setError({ severity: 'warning', msg: '' });
    if (!newFile) {
      return;
    }

    const fileType = newFile.type.split('/')[1];
    if (!allowedFileTypes.includes(fileType)) {
      setError({ severity: 'error', msg: `Formato de archivo no soportado (${fileType}).` });
      return;
    }

    if (newFile.size > 2 * 1024 * 1024) {
      if (fileType === 'pdf') {
        setError({
          severity: 'error',
          msg: `El PDF ${newFile.name} no puede exceder el tamaño máximo de 2 MB.`
        });
        return;
      }
      setError({
        severity: 'warning',
        msg: (
          <>
            La imagen {newFile.name} excede el tamaño máximo de 2 MB, se cargará pero en{' '}
            <b>menor calidad</b>.
          </>
        )
      });
    }

    setFile(newFile);
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <div style={{ display: 'flex', gap: '5px' }}>
      <ModalComponent
        modalTitle='Contra respuesta'
        disabledModalBtn={loading}
        toggleModal={handleToggleModal}
        isModalOpen={open}
        body={
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <InputField
              label='Comentario'
              value={comentario}
              type='text'
              handleInputChange={(e) => {
                setComentario(e.target.value);
              }}
              maxLength={300}
              disabled={loading}
              fullWidth
              required
            />
            <div className='trazabilidad-file-uploader-container'>
              <FileUploader
                classes='trazabilidad-file-uploader'
                handleChange={handleChangeFiles}
                name='file'
                label='Cargue o arrastre el archivo aquí'
                hoverTitle='Soltar aquí'
                types={allowedFileTypes}
                fileOrFiles={file}
                disabled={loading}
                multiple={false}
                required
                onTypeError={(err: any) => {
                  if (err === 'File type is not supported') {
                    setError({ severity: 'error', msg: `Formato de archivo no soportado.` });
                    setFile(null);
                  }
                }}
              />
              {error.msg && <Alert severity={error.severity}>{error.msg}</Alert>}
              {file && (
                <div className='trazabilidad-file-uploader-file-list-container'>
                  <h3 className='trazabilidad-file-uploader-file-list-title'>Archivos cargados:</h3>
                  <p className='trazabilidad-file-uploader-file-list-item'>
                    <span
                      className='trazabilidad-file-uploader-file-list-item-remove'
                      onClick={() => setFile(null)}>
                      X
                    </span>{' '}
                    {file.name}
                  </p>
                </div>
              )}
            </div>
          </div>
        }
        actionBtn={
          <ConnectedBtn
            onClick={onClickSi}
            disabled={loading || !comentario || !file}
            color='success'
            variant='contained'
            style={{ gap: '2px' }}>
            Objetar
          </ConnectedBtn>
        }
        btnLabel='Si'
        btnVariant='contained'
        btnColor='info'
        customStyles={{
          modalContainer: {
            height: 'auto'
          }
        }}
      />

      <ConnectedBtn
        onClick={onClickNo}
        disabled={loading}
        color='error'
        variant='contained'
        style={{ gap: '2px' }}>
        No
      </ConnectedBtn>
    </div>
  );
};

export const ContraResponderOcaPorDestino: FunctionComponent<{
  rowData: OCA;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(contraResponderOcaPorDestino);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaObjetadaTransportista, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.objetadaTransportista].reopenedDays
        : stateRules[EstadoOCA.objetadaTransportista].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(
            dateFormatFn(rowData.fechaOcaObjetadaTransportista, 'YYYY-MM-DD HH:mm:ss', true)
          ),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.objetadaTransportista].reopenedDays
            : stateRules[EstadoOCA.objetadaTransportista].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaObjetadaTransportista]);

  const entity =
    tipoCobro === TipoCobro.faltante
      ? newE.OcaFaltanteObjetadaTransportista
      : newE.OcaMermaObjetadaTransportista;

  const onClickNo = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas NO contra responder?',
      text: 'La OCA se cerrará',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, contraRespuesta: false, tipoCobro },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickSi = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Confirmas la contra respuesta?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              {
                ocaId: rowData._id,
                contraRespuesta: true,
                tipoCobro,
                comentario
              },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <div style={{ display: 'flex', gap: '5px' }}>
      <ModalComponent
        modalTitle='Contra respuesta'
        disabledModalBtn={loading}
        toggleModal={handleToggleModal}
        isModalOpen={open}
        body={
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
            <InputField
              label='Comentario'
              value={comentario}
              type='text'
              handleInputChange={(e) => {
                setComentario(e.target.value);
              }}
              maxLength={300}
              disabled={loading}
              fullWidth
              required
            />
          </div>
        }
        actionBtn={
          <ConnectedBtn
            onClick={onClickSi}
            disabled={loading || !comentario}
            color='success'
            variant='contained'
            style={{ gap: '2px' }}>
            Contra responder
          </ConnectedBtn>
        }
        btnLabel='Si'
        btnVariant='contained'
        btnColor='info'
        customStyles={{
          modalContainer: {
            height: 'auto'
          }
        }}
      />

      <ConnectedBtn
        onClick={onClickNo}
        disabled={loading}
        color='error'
        variant='contained'
        style={{ gap: '2px' }}>
        No
      </ConnectedBtn>
    </div>
  );
};

export const DecisionEnDiscusionOCA: FunctionComponent<{
  rowData: OCA;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(decisionEnDiscusionOCA);
  const [loading, setLoading] = useState<boolean>(false);
  const [comentario, setComentario] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<boolean>(
    hasTimePassed(
      new Date(dateFormatFn(rowData.fechaOcaEnDiscusion, 'YYYY-MM-DD HH:mm:ss', true)),
      rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
        ? stateRules[EstadoOCA.enDiscusion].reopenedDays
        : stateRules[EstadoOCA.enDiscusion].normalDays
    ).hasPassed
  );
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(
        hasTimePassed(
          new Date(dateFormatFn(rowData.fechaOcaEnDiscusion, 'YYYY-MM-DD HH:mm:ss', true)),
          rowData.ocaReabierta && rowData.estadoOcaActual === rowData.estadoOcaAnterior
            ? stateRules[EstadoOCA.enDiscusion].reopenedDays
            : stateRules[EstadoOCA.enDiscusion].normalDays
        ).hasPassed
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [rowData.fechaOcaEnDiscusion]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteEnDiscusion : newE.OcaMermaEnDiscusion;

  const onClickCD = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas hacer responsable al CD?',
      text: 'Esto provocará que la OCA se de por CERRADA',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, cobrable: false, tipoCobro, comentario },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const onClickTransportista = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas hacer responsable al Transportista?',
      text: 'Esto provocará que la OCA quede disponible para ser COBRADA',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;

        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              { ocaId: rowData._id, cobrable: true, tipoCobro, comentario },
              entity
            );
            setLoading(false);
            if (res) {
              setComentario('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return timeLeft ? (
    <Alert severity='error'>Se acabó el tiempo</Alert>
  ) : (
    <ModalComponent
      modalTitle='¿Quien es responsable?'
      disabledModalBtn={loading}
      toggleModal={handleToggleModal}
      isModalOpen={open}
      body={
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
          <InputField
            label='Comentario (opcional)'
            value={comentario}
            type='text'
            handleInputChange={(e) => {
              setComentario(e.target.value);
            }}
            maxLength={300}
            disabled={loading}
            fullWidth
          />
        </div>
      }
      actionBtn={
        <div
          style={{
            display: 'flex',
            gap: '5px',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
          <ConnectedBtn
            onClick={onClickCD}
            disabled={loading}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            <WarehouseIcon />
            CD
          </ConnectedBtn>

          <ConnectedBtn
            onClick={onClickTransportista}
            disabled={loading}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            <LocalShippingIcon />
            EETT
          </ConnectedBtn>
        </div>
      }
      btnLabel='Decidir'
      btnVariant='contained'
      btnColor='info'
      customStyles={{
        modalContainer: {
          height: 'auto'
        },
        modalBtnContainer: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    />
  );
};

export const CrearCobro: FunctionComponent<{
  selected: Array<OCA>;
  tipoCobro: TipoCobro;
}> = ({ selected, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(crearCobro);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [transportistasDiferentes, setTransportistasDiferentes] = useState<boolean>(false);

  const selectedOcas = useMemo(
    () =>
      selected.reduce(
        (acc, obj) => {
          if (acc.every((a) => a._id !== obj._id)) {
            acc.push({
              _id: obj._id,
              oca: obj.oca,
              nombreTransportista: obj.nombreTransportista,
              fechaOcaCobrable: obj.fechaOcaCobrable,
              ocaReabierta: obj.ocaReabierta,
              estadoOcaActual: obj.estadoOcaActual,
              estadoOcaAnterior: obj.estadoOcaAnterior
            });
          }
          return acc;
        },
        [] as Array<{
          _id: string;
          oca: string;
          nombreTransportista: string;
          fechaOcaCobrable: string;
          ocaReabierta: boolean;
          estadoOcaActual: string;
          estadoOcaAnterior: string;
        }>
      ),
    [selected]
  );

  useEffect(() => {
    if (selectedOcas.length === 0) {
      setError(undefined);
      return;
    }
    const primerTransportista = selectedOcas[0]?.nombreTransportista;

    setTransportistasDiferentes(
      selectedOcas.some((obj) => obj.nombreTransportista !== primerTransportista)
    );
  }, [selectedOcas]);

  useEffect(() => {
    if (transportistasDiferentes) {
      setError('Los cobros deben ser para un mismo Transportista');
    } else {
      setError(undefined);
    }
  }, [transportistasDiferentes]);

  useEffect(() => {
    const validarDatos = () => {
      for (const obj of selectedOcas) {
        const startDate = new Date(dateFormatFn(obj.fechaOcaCobrable, 'YYYY-MM-DD HH:mm:ss', true));
        if (
          hasTimePassed(
            startDate,
            obj.ocaReabierta && obj.estadoOcaActual === obj.estadoOcaAnterior
              ? stateRules[EstadoOCA.cobrable].reopenedDays
              : stateRules[EstadoOCA.cobrable].normalDays
          ).hasPassed
        ) {
          setError(`La OCA ${obj.oca} ha vencido.`);
          return;
        }
      }

      if (transportistasDiferentes) {
        setError('Los cobros deben ser para un mismo Transportista');
      } else {
        setError(undefined);
      }
    };

    const intervalId = setInterval(() => {
      validarDatos();
    }, 500);

    return () => clearInterval(intervalId);
  }, [selectedOcas, transportistasDiferentes]);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteCobrable : newE.OcaMermaCobrable;

  const onClickTransportista = async (buttonParams: ButtonParams) => {
    if (selectedOcas.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Debes seleccionar al menos una OCA'
      });
      return;
    }
    const ocas = selectedOcas.map((s) => s.oca);
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas realizar el Cobro?',
      html: `<div style="display: flex; flex-direction: column">
              <h4>Crearas un cobro con las siguientes OCAs:</h4>
              <div style="max-height: 30vh; overflow-y: auto">
                <ul>
                  ${ocas.map((o) => `<li style="text-align: left">${o}</li>`).join('')}
                </ul>
              </div>
            </div>`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({ ocas: selectedOcas, tipoCobro }, entity);
            setLoading(false);
            if (res) {
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return selectedOcas.length > 0 ? (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <ConnectedBtn
        onClick={onClickTransportista}
        disabled={loading || !!error}
        color='success'
        variant='contained'
        style={{ gap: '5px' }}>
        <CurrencyExchangeIcon />
        Generar Cobro ({selectedOcas.length})
      </ConnectedBtn>

      {error && (
        <Alert variant='filled' severity='error'>
          {error}
        </Alert>
      )}
    </div>
  ) : (
    <></>
  );
};

export const ReabrirOca: FunctionComponent<{
  rowData: OCA;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(reabrirOca);
  const [loading, setLoading] = useState<boolean>(false);

  const entity =
    tipoCobro === TipoCobro.faltante ? newE.OcaFaltanteExpirada : newE.OcaMermaExpirada;

  const onClick = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: '¿Seguro de que deseas reabrir la OCA?',
      text: `Esto provocará que la OCA vuelva al estado '${rowData.estadoOcaAnterior}'`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action(
              {
                ocaId: rowData._id,
                tipoCobro,
                estadoOcaActual: rowData.estadoOcaActual,
                estadoOcaAnterior: rowData.estadoOcaAnterior
              },
              entity
            );
            setLoading(false);
            if (res) {
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return (
    <ConnectedBtn
      onClick={onClick}
      disabled={loading}
      color='primary'
      variant='contained'
      style={{ gap: '5px' }}>
      <RestoreIcon />
      Reabrir
    </ConnectedBtn>
  );
};

export const AgregarIdCobroCcu: FunctionComponent<{
  rowData: Cobro;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(agregarIdCobroCcu);
  const [loading, setLoading] = useState<boolean>(false);
  const [idCobroCcu, setIdCobroCcu] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const onClick = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: `¿Seguro de que deseas asignar el ID '${idCobroCcu}'?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);
            const res = await action({
              cobroId: rowData.cobroId,
              tipoCobro,
              idCobroCcu
            });
            setLoading(false);
            if (res) {
              setIdCobroCcu('');
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  return (
    <ModalComponent
      modalTitle='Agregar ID Cobro'
      disabledModalBtn={loading}
      toggleModal={handleToggleModal}
      isModalOpen={open}
      body={
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
          <InputField
            label='ID Cobro'
            value={idCobroCcu}
            type='text'
            handleInputChange={(e) => {
              setIdCobroCcu((e.target.value || '').replace(' ', ''));
            }}
            disabled={loading}
            fullWidth
            required
          />
        </div>
      }
      actionBtn={
        <div
          style={{
            display: 'flex',
            gap: '5px',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
          <ConnectedBtn
            onClick={onClick}
            disabled={loading || !idCobroCcu}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            Agregar
          </ConnectedBtn>
        </div>
      }
      btnLabel='Agregar Id Cobro'
      btnVariant='contained'
      btnColor='success'
      customStyles={{
        modalContainer: {
          height: 'auto'
        },
        modalBtnContainer: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    />
  );
};

export const AgregarCartaCobranza: FunctionComponent<{
  rowData: Cobro;
  tipoCobro: TipoCobro;
}> = ({ rowData, tipoCobro }) => {
  const ConnectedBtn = connectedSecondaryButton(agregarCartaCobranza);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const handleToggleModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const onClick = async (buttonParams: ButtonParams) => {
    Swal.fire({
      icon: 'question',
      title: `¿Seguro de que deseas asignar la carta de cobranzas: ${file?.name}?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { action } = buttonParams;
        toast.promise(
          async () => {
            setLoading(true);

            const processedFiles = await transformFilesToString(file ? [file] : null);

            const res = await action({
              cobroId: rowData.cobroId,
              tipoCobro,
              file: processedFiles
            });
            setLoading(false);
            if (res) {
              setFile(null);
              handleToggleModal(false);
              return res;
            }
            throw Error;
          },
          {
            pending: 'Cargando...',
            success: 'Accion realizada',
            error: 'Ocurrió un error al realizar la acción'
          }
        );
      }
    });
  };

  const handleChangeFiles = (newFile: File | null) => {
    if (!newFile) {
      setError('');
      return;
    }

    if (newFile.size > 5 * 1024 * 1024) {
      setError(`El archivo ${newFile.name} excede el tamaño máximo de 5 MB.`);
      return;
    }

    setFile(newFile);
    setError('');
  };

  return (
    <ModalComponent
      modalTitle='Agregar carta cobranza'
      disabledModalBtn={loading}
      toggleModal={handleToggleModal}
      isModalOpen={open}
      body={
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
          <div className='trazabilidad-file-uploader-container'>
            <FileUploader
              classes='trazabilidad-file-uploader'
              handleChange={handleChangeFiles}
              name='file'
              label='Cargue o arrastre el PDF aquí'
              hoverTitle='Soltar aquí'
              types={['pdf']}
              fileOrFiles={file}
              disabled={loading}
              multiple={false}
              required
            />
            {error && <Alert severity='warning'>{error}</Alert>}
            {file && (
              <div className='trazabilidad-file-uploader-file-list-container'>
                <h3 className='trazabilidad-file-uploader-file-list-title'>Archivos cargados:</h3>
                <p className='trazabilidad-file-uploader-file-list-item'>
                  <span
                    className='trazabilidad-file-uploader-file-list-item-remove'
                    onClick={() => setFile(null)}>
                    X
                  </span>{' '}
                  {file.name}
                </p>
              </div>
            )}
          </div>
        </div>
      }
      actionBtn={
        <div
          style={{
            display: 'flex',
            gap: '5px',
            width: '100%',
            justifyContent: 'flex-end'
          }}>
          <ConnectedBtn
            onClick={onClick}
            disabled={loading || !file}
            color='primary'
            variant='contained'
            style={{ gap: '5px' }}>
            Agregar
          </ConnectedBtn>
        </div>
      }
      btnLabel='Agregar archivo'
      btnVariant='contained'
      btnColor='success'
      customStyles={{
        modalContainer: {
          height: 'auto'
        },
        modalBtnContainer: {
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }
      }}
    />
  );
};
