import React, { useEffect, useMemo, useState } from 'react';
import { OCA, EstadoOCA, TipoUsuarioValues, stateRules } from '../../types';
import { ExtendedColumnDef, WebEntity } from './types';
import { dateFormatFn, formatStringNumber } from '../../utils/utils';
import { Alert, Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import {
  ObjetarOcaPorEett,
  DecisionEnDiscusionOCA,
  ObjetarOcaPorOrigen,
  ContraResponderOcaPorDestino,
  ReabrirOca
} from '../../components/SecondaryButton';
import { DownloadFile, hasTimePassed } from '../utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BlockIcon from '@mui/icons-material/Block';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2e7d32',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

const OcaEntity: WebEntity<OCA> = {
  name: 'ocaMermaIngresada',
  endpoint: 'cobros/ocas',
  referenceColumn: 'oca',
  tableColumns: [
    {
      header: 'ID',
      accessorKey: '_id',
      omitExport: true,
      columnType: 'text',
      enableHiding: false,
      visibleInShowHideMenu: false
    },
    {
      header: 'OCA',
      accessorKey: 'oca',
      columnType: 'text',
      size: 140
    },
    {
      header: 'Transporte',
      accessorKey: 'nombreTransportista',
      columnType: 'text',
      size: 160,
      AggregatedCell: ({ row }) => row.original?.nombreTransportista,
      Cell: () => ''
    },
    {
      header: 'CD Origen',
      accessorKey: 'nombreCentroOrigen',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => row.original?.nombreCentroOrigen,
      Cell: () => ''
    },
    {
      header: 'CD Destino',
      accessorKey: 'nombreCentroDestino',
      columnType: 'text',
      size: 140,
      AggregatedCell: ({ row }) => row.original?.nombreCentroDestino,
      Cell: () => ''
    },
    {
      header: 'SKU',
      accessorKey: 'material',
      columnType: 'text',
      size: 120
    },
    {
      header: 'Descripcion SKU',
      accessorKey: 'textoBreveMaterial',
      columnType: 'text',
      size: 200
    },
    {
      header: 'Cantidad de cajas',
      accessorKey: 'cantidadCajas',
      columnType: 'numeric',
      size: 180,
      Cell: ({ row }) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          {formatStringNumber(row?.original.cantidadCajas, 0, 0)}
        </div>
      ),
      AggregatedCell: ({ cell }) => {
        const sum = cell.getValue();
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Box
              sx={{
                fontWeight: 'bold'
              }}>
              {sum && formatStringNumber(sum?.toString(), 0, 0)}
            </Box>
          </div>
        );
      },
      Footer: ({ table }: { table: any }) => {
        const rows = table.getFilteredRowModel().rows.map((r: any) => r.original);
        const total = rows.reduce(
          (acc: number, obj: OCA) => (obj.cantidadCajas ? acc + Number(obj.cantidadCajas) : acc),
          0
        );
        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Stack sx={{ fontSize: 15, color: 'green' }}>
              Total: {formatStringNumber(total.toString(), 0, 0)}
            </Stack>
          </div>
        );
      }
    },
    {
      header: 'Detalle del cobro',
      accessorKey: 'detalle',
      columnType: 'text',
      size: 180,
      AggregatedCell: ({ row }) => row.original?.detalle,
      Cell: () => ''
    },
    {
      header: 'Causal',
      accessorKey: 'nombreCausal',
      columnType: 'text',
      size: 170,
      AggregatedCell: ({ row }) => row.original?.nombreCausal,
      Cell: () => ''
    },
    {
      header: 'Evidencia OCA',
      accessorKey: 'evidencia',
      columnType: 'text',
      size: 160,
      omitExport: true,
      AggregatedCell: ({ row }) =>
        row.original.evidencia ? (
          <DownloadFile
            fileName={row.original.evidencia}
            fileType='application/zip'
            buttonName='Evidencia OCA'
          />
        ) : (
          ''
        ),
      Cell: () => ''
    },
    {
      header: 'Fecha OCA',
      accessorKey: 'fechaOca',
      columnType: 'dateUTC',
      size: 180,
      accessorFn: (row) => dateFormatFn(row.fechaOca, 'DD/MM/YYYY', true),
      AggregatedCell: ({ row }) => dateFormatFn(row.original.fechaOca, 'DD/MM/YYYY', true),
      Cell: () => ''
    },
    {
      header: 'Fecha ingreso OCA',
      accessorKey: 'fechaOcaIngresada',
      columnType: 'dateTimeUTC',
      size: 200,
      accessorFn: (row) => dateFormatFn(row.fechaOcaIngresada, 'DD/MM/YYYY HH:mm:ss', true),
      AggregatedCell: ({ row }) =>
        dateFormatFn(row.original.fechaOcaIngresada, 'DD/MM/YYYY HH:mm:ss', true),
      Cell: () => ''
    }
  ]
};

export default OcaEntity;

export const AdditionalColumns: (estadoOca: EstadoOCA) => ExtendedColumnDef<OCA>[] = (
  estadoOca
) => {
  const CheckAccess = (tipoUsuario: TipoUsuarioValues) => {
    const auth = useSelector((state: AppState) => state.auth);
    const nombreTipoUsuario = useMemo(() => auth.user?.nombreTipoUsuario, [auth.user]);
    return nombreTipoUsuario === tipoUsuario;
  };

  const CheckZones = (zona: string) => {
    const auth = useSelector((state: AppState) => state.auth);
    const zonas = useMemo(() => auth.user?.zonas, [auth.user]);
    return zonas?.includes(zona);
  };

  // ----------------------------------------------- OCA INGRESADA ---------------------------------------------------------
  if (estadoOca === EstadoOCA.ingresada) {
    return [
      {
        header: '¿Objetar?',
        accessorKey: 'action',
        columnType: 'text',
        size: 160,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          CheckAccess(TipoUsuarioValues.Operador) &&
          CheckZones(row.original.nombreCentroOrigen) &&
          row.original.estadoOcaActual === EstadoOCA.ingresada ? (
            <ObjetarOcaPorOrigen
              rowData={row.original as OCA}
              tipoCobro={row.original.nombreTipoDesviacion}
            />
          ) : (
            <Tooltip title='Solo los usuarios representantes del CD Origen pueden OBJETAR'>
              <Alert icon={<BlockIcon fontSize='inherit' />} severity='error'>
                Sin acceso
              </Alert>
            </Tooltip>
          )
      },
      {
        header: 'Tiempo restante',
        accessorKey: 'timeLeft',
        columnType: 'text',
        size: 180,
        omitExport: true,
        AggregatedCell: ({ row }) => {
          const fecha = useMemo(() => row.original.fechaOcaIngresada, [row.original]);
          const startDate = useMemo(
            () => new Date(dateFormatFn(fecha, 'YYYY-MM-DD HH:mm:ss', true)),
            [fecha]
          );
          const [timeLeft, setTimeLeft] = useState<number>(
            hasTimePassed(
              startDate,
              row.original.ocaReabierta &&
                row.original.estadoOcaActual === row.original.estadoOcaAnterior
                ? stateRules[EstadoOCA.ingresada].reopenedDays
                : stateRules[EstadoOCA.ingresada].normalDays
            ).remainingTime
          );

          useEffect(() => {
            const intervalId = setInterval(() => {
              setTimeLeft(
                hasTimePassed(
                  startDate,
                  row.original.ocaReabierta &&
                    row.original.estadoOcaActual === row.original.estadoOcaAnterior
                    ? stateRules[EstadoOCA.ingresada].reopenedDays
                    : stateRules[EstadoOCA.ingresada].normalDays
                ).remainingTime
              );
            }, 1000);

            return () => clearInterval(intervalId);
          }, [startDate]);

          const formatTime = (milliseconds: number) => {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const days = Math.floor(totalSeconds / (3600 * 24));
            const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
          };

          return (
            <div style={{ display: 'flex', gap: '5px', cursor: 'help' }}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color='inherit'>Fecha ingreso</Typography>
                    {dateFormatFn(fecha, 'DD/MM/YYYY HH:mm:ss', true)}
                  </React.Fragment>
                }>
                <InfoOutlinedIcon color='info' />
              </HtmlTooltip>

              <span>{formatTime(timeLeft)}</span>
            </div>
          );
        }
      }
    ];
  }
  // ------------------------------------------ OCA Objetada Origen -------------------------------------------------------
  else if (estadoOca === EstadoOCA.objetadaOrigen) {
    return [
      {
        header: 'Fecha objeción por CD Origen',
        accessorKey: 'fechaOcaObjetadaOrigen',
        columnType: 'dateTimeUTC',
        size: 250,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) => dateFormatFn(row.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Objeción por CD Origen',
        accessorKey: 'comentarioObjecionOrigen',
        columnType: 'text',
        size: 280,
        AggregatedCell: ({ row }) => row.original.comentarioObjecionOrigen,
        Cell: () => ''
      },
      {
        header: 'Archivo objeción por CD Origen',
        accessorKey: 'nombreArchivoObjecionOrigen',
        columnType: 'text',
        size: 260,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          row.original.nombreArchivoObjecionOrigen ? (
            <DownloadFile
              fileName={row.original.nombreArchivoObjecionOrigen}
              fileType='application/zip'
              buttonName='Objecion'
            />
          ) : (
            ''
          ),
        Cell: () => ''
      },
      {
        header: '¿Objetar?',
        accessorKey: 'action',
        columnType: 'text',
        size: 190,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          CheckAccess(TipoUsuarioValues.Transportista) &&
          row.original.estadoOcaActual === EstadoOCA.objetadaOrigen ? (
            <ObjetarOcaPorEett
              rowData={row.original as OCA}
              tipoCobro={row.original.nombreTipoDesviacion}
            />
          ) : (
            <Tooltip title='Solo los usuarios representantes de la EETT pueden objetar'>
              <Alert icon={<BlockIcon fontSize='inherit' />} severity='error'>
                Sin acceso
              </Alert>
            </Tooltip>
          )
      },
      {
        header: 'Tiempo restante',
        accessorKey: 'timeLeft',
        columnType: 'text',
        size: 180,
        omitExport: true,
        AggregatedCell: ({ row }) => {
          const fecha = useMemo(() => row.original.fechaOcaObjetadaOrigen, [row.original]);
          const startDate = useMemo(
            () => new Date(dateFormatFn(fecha, 'YYYY-MM-DD HH:mm:ss', true)),
            [fecha]
          );
          const [timeLeft, setTimeLeft] = useState<number>(
            hasTimePassed(
              startDate,
              row.original.ocaReabierta &&
                row.original.estadoOcaActual === row.original.estadoOcaAnterior
                ? stateRules[EstadoOCA.objetadaOrigen].reopenedDays
                : stateRules[EstadoOCA.objetadaOrigen].normalDays
            ).remainingTime
          );

          useEffect(() => {
            const intervalId = setInterval(() => {
              setTimeLeft(
                hasTimePassed(
                  startDate,
                  row.original.ocaReabierta &&
                    row.original.estadoOcaActual === row.original.estadoOcaAnterior
                    ? stateRules[EstadoOCA.objetadaOrigen].reopenedDays
                    : stateRules[EstadoOCA.objetadaOrigen].normalDays
                ).remainingTime
              );
            }, 1000);

            return () => clearInterval(intervalId);
          }, [startDate]);

          const formatTime = (milliseconds: number) => {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const days = Math.floor(totalSeconds / (3600 * 24));
            const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
          };

          return (
            <div style={{ display: 'flex', gap: '5px', cursor: 'help' }}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color='inherit'>Fecha objeción por CD Origen</Typography>
                    {dateFormatFn(fecha, 'DD/MM/YYYY HH:mm:ss', true)}
                  </React.Fragment>
                }>
                <InfoOutlinedIcon color='info' />
              </HtmlTooltip>

              <span>{formatTime(timeLeft)}</span>
            </div>
          );
        }
      }
    ];
  }
  // -------------------------------------- OCA Objetada Transportista ----------------------------------------------------
  else if (estadoOca === EstadoOCA.objetadaTransportista) {
    return [
      {
        header: 'Fecha objeción por CD Origen',
        accessorKey: 'fechaOcaObjetadaOrigen',
        columnType: 'dateTimeUTC',
        size: 250,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) => dateFormatFn(row.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Objeción por CD Origen',
        accessorKey: 'comentarioObjecionOrigen',
        columnType: 'text',
        size: 280,
        AggregatedCell: ({ row }) => row.original.comentarioObjecionOrigen,
        Cell: () => ''
      },
      {
        header: 'Archivo objeción por CD Origen',
        accessorKey: 'nombreArchivoObjecionOrigen',
        columnType: 'text',
        size: 260,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          row.original.nombreArchivoObjecionOrigen ? (
            <DownloadFile
              fileName={row.original.nombreArchivoObjecionOrigen}
              fileType='application/zip'
              buttonName='Objecion'
            />
          ) : (
            ''
          ),
        Cell: () => ''
      },
      {
        header: 'Fecha objeción por EETT',
        accessorKey: 'fechaOcaObjetadaTransportista',
        columnType: 'dateTimeUTC',
        size: 220,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) =>
          dateFormatFn(row.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Objeción por EETT',
        accessorKey: 'comentarioObjecionTransportista',
        columnType: 'text',
        size: 260,
        AggregatedCell: ({ row }) => row.original.comentarioObjecionTransportista,
        Cell: () => ''
      },
      {
        header: 'Archivo objeción por EETT',
        accessorKey: 'nombreArchivoObjecionTransportista',
        columnType: 'text',
        size: 240,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          row.original.nombreArchivoObjecionTransportista ? (
            <DownloadFile
              fileName={row.original.nombreArchivoObjecionTransportista}
              fileType='application/pdf'
              buttonName='Objecion'
            />
          ) : (
            ''
          ),
        Cell: () => ''
      },
      {
        header: '¿Contra responder?',
        accessorKey: 'action',
        columnType: 'text',
        size: 190,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          CheckAccess(TipoUsuarioValues.Operador) &&
          CheckZones(row.original.nombreCentroDestino) &&
          row.original.estadoOcaActual === EstadoOCA.objetadaTransportista ? (
            <ContraResponderOcaPorDestino
              rowData={row.original as OCA}
              tipoCobro={row.original.nombreTipoDesviacion}
            />
          ) : (
            <Tooltip title='Solo los usuarios representantes del CD Destino pueden OBJETAR'>
              <Alert icon={<BlockIcon fontSize='inherit' />} severity='error'>
                Sin acceso
              </Alert>
            </Tooltip>
          )
      },
      {
        header: 'Tiempo restante',
        accessorKey: 'timeLeft',
        columnType: 'text',
        size: 180,
        omitExport: true,
        AggregatedCell: ({ row }) => {
          const fecha = useMemo(() => row.original.fechaOcaObjetadaTransportista, [row.original]);
          const startDate = useMemo(
            () => new Date(dateFormatFn(fecha, 'YYYY-MM-DD HH:mm:ss', true)),
            [fecha]
          );
          const [timeLeft, setTimeLeft] = useState<number>(
            hasTimePassed(
              startDate,
              row.original.ocaReabierta &&
                row.original.estadoOcaActual === row.original.estadoOcaAnterior
                ? stateRules[EstadoOCA.objetadaTransportista].reopenedDays
                : stateRules[EstadoOCA.objetadaTransportista].normalDays
            ).remainingTime
          );

          useEffect(() => {
            const intervalId = setInterval(() => {
              setTimeLeft(
                hasTimePassed(
                  startDate,
                  row.original.ocaReabierta &&
                    row.original.estadoOcaActual === row.original.estadoOcaAnterior
                    ? stateRules[EstadoOCA.objetadaTransportista].reopenedDays
                    : stateRules[EstadoOCA.objetadaTransportista].normalDays
                ).remainingTime
              );
            }, 1000);

            return () => clearInterval(intervalId);
          }, [startDate]);

          const formatTime = (milliseconds: number) => {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const days = Math.floor(totalSeconds / (3600 * 24));
            const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
          };

          return (
            <div style={{ display: 'flex', gap: '5px', cursor: 'help' }}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color='inherit'>Fecha objeción por EETT</Typography>
                    {dateFormatFn(fecha, 'DD/MM/YYYY HH:mm:ss', true)}
                  </React.Fragment>
                }>
                <InfoOutlinedIcon color='info' />
              </HtmlTooltip>

              <span>{formatTime(timeLeft)}</span>
            </div>
          );
        }
      }
    ];
  }
  // ------------------------------------------- OCA En discusion ----------------------------------------------------------
  else if (estadoOca === EstadoOCA.enDiscusion) {
    return [
      {
        header: 'Fecha objeción por CD Origen',
        accessorKey: 'fechaOcaObjetadaOrigen',
        columnType: 'dateTimeUTC',
        size: 250,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) => dateFormatFn(row.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Objeción por CD Origen',
        accessorKey: 'comentarioObjecionOrigen',
        columnType: 'text',
        size: 280,
        AggregatedCell: ({ row }) => row.original.comentarioObjecionOrigen,
        Cell: () => ''
      },
      {
        header: 'Archivo objeción por CD Origen',
        accessorKey: 'nombreArchivoObjecionOrigen',
        columnType: 'text',
        size: 260,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          row.original.nombreArchivoObjecionOrigen ? (
            <DownloadFile
              fileName={row.original.nombreArchivoObjecionOrigen}
              fileType='application/zip'
              buttonName='Objecion'
            />
          ) : (
            ''
          ),
        Cell: () => ''
      },
      {
        header: 'Fecha objeción por EETT',
        accessorKey: 'fechaOcaObjetadaTransportista',
        columnType: 'dateTimeUTC',
        size: 220,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) =>
          dateFormatFn(row.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Objeción por EETT',
        accessorKey: 'comentarioObjecionTransportista',
        columnType: 'text',
        size: 260,
        AggregatedCell: ({ row }) => row.original.comentarioObjecionTransportista,
        Cell: () => ''
      },
      {
        header: 'Archivo objeción por EETT',
        accessorKey: 'nombreArchivoObjecionTransportista',
        columnType: 'text',
        size: 240,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          row.original.nombreArchivoObjecionTransportista ? (
            <DownloadFile
              fileName={row.original.nombreArchivoObjecionTransportista}
              fileType='application/pdf'
              buttonName='Objecion'
            />
          ) : (
            ''
          ),
        Cell: () => ''
      },
      {
        header: 'Comentario Contra Objeción por CD Destino',
        accessorKey: 'comentarioContraObjecionDestino',
        columnType: 'text',
        size: 330,
        AggregatedCell: ({ row }) => row.original.comentarioContraObjecionDestino,
        Cell: () => ''
      },
      {
        header: '¿Responsable?',
        accessorKey: 'action',
        columnType: 'text',
        size: 190,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          CheckAccess(TipoUsuarioValues.AdminCobros) &&
          row.original.estadoOcaActual === EstadoOCA.enDiscusion ? (
            <DecisionEnDiscusionOCA
              rowData={row.original as OCA}
              tipoCobro={row.original.nombreTipoDesviacion}
            />
          ) : (
            <Tooltip title='Solo los Administradores de cobro pueden mediar una discusion'>
              <Alert icon={<BlockIcon fontSize='inherit' />} severity='error'>
                Sin acceso
              </Alert>
            </Tooltip>
          )
      },
      {
        header: 'Tiempo restante',
        accessorKey: 'timeLeft',
        columnType: 'text',
        size: 180,
        omitExport: true,
        AggregatedCell: ({ row }) => {
          const fecha = useMemo(() => row.original.fechaOcaEnDiscusion, [row.original]);
          const startDate = useMemo(
            () => new Date(dateFormatFn(fecha, 'YYYY-MM-DD HH:mm:ss', true)),
            [fecha]
          );
          const [timeLeft, setTimeLeft] = useState<number>(
            hasTimePassed(
              startDate,
              row.original.ocaReabierta &&
                row.original.estadoOcaActual === row.original.estadoOcaAnterior
                ? stateRules[EstadoOCA.enDiscusion].reopenedDays
                : stateRules[EstadoOCA.enDiscusion].normalDays
            ).remainingTime
          );

          useEffect(() => {
            const intervalId = setInterval(() => {
              setTimeLeft(
                hasTimePassed(
                  startDate,
                  row.original.ocaReabierta &&
                    row.original.estadoOcaActual === row.original.estadoOcaAnterior
                    ? stateRules[EstadoOCA.enDiscusion].reopenedDays
                    : stateRules[EstadoOCA.enDiscusion].normalDays
                ).remainingTime
              );
            }, 1000);

            return () => clearInterval(intervalId);
          }, [startDate]);

          const formatTime = (milliseconds: number) => {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const days = Math.floor(totalSeconds / (3600 * 24));
            const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
          };

          return (
            <div style={{ display: 'flex', gap: '5px', cursor: 'help' }}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color='inherit'>En discusion desde</Typography>
                    {dateFormatFn(fecha, 'DD/MM/YYYY HH:mm:ss', true)}
                  </React.Fragment>
                }>
                <InfoOutlinedIcon color='info' />
              </HtmlTooltip>

              <span>{formatTime(timeLeft)}</span>
            </div>
          );
        }
      }
    ];
  }
  // ---------------------------------------------- OCA Cobrable -----------------------------------------------------------
  else if (estadoOca === EstadoOCA.cobrable) {
    return [
      {
        header: 'Cobrable desde',
        accessorKey: 'fechaOcaCobrable',
        columnType: 'dateTimeUTC',
        size: 200,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaCobrable, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) => dateFormatFn(row.fechaOcaCobrable, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Tiempo restante',
        accessorKey: 'timeLeft',
        columnType: 'text',
        size: 180,
        omitExport: true,
        AggregatedCell: ({ row }) => {
          const fecha = useMemo(() => row.original.fechaOcaCobrable, [row.original]);
          const startDate = useMemo(
            () => new Date(dateFormatFn(fecha, 'YYYY-MM-DD HH:mm:ss', true)),
            [fecha]
          );
          const [timeLeft, setTimeLeft] = useState<number>(
            hasTimePassed(
              startDate,
              row.original.ocaReabierta &&
                row.original.estadoOcaActual === row.original.estadoOcaAnterior
                ? stateRules[EstadoOCA.cobrable].reopenedDays
                : stateRules[EstadoOCA.cobrable].normalDays
            ).remainingTime
          );

          useEffect(() => {
            const intervalId = setInterval(() => {
              setTimeLeft(
                hasTimePassed(
                  startDate,
                  row.original.ocaReabierta &&
                    row.original.estadoOcaActual === row.original.estadoOcaAnterior
                    ? stateRules[EstadoOCA.cobrable].reopenedDays
                    : stateRules[EstadoOCA.cobrable].normalDays
                ).remainingTime
              );
            }, 1000);

            return () => clearInterval(intervalId);
          }, [startDate]);

          const formatTime = (milliseconds: number) => {
            const totalSeconds = Math.floor(milliseconds / 1000);
            const days = Math.floor(totalSeconds / (3600 * 24));
            const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
          };

          return (
            <div style={{ display: 'flex', gap: '5px', cursor: 'help' }}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color='inherit'>Cobrable desde</Typography>
                    {dateFormatFn(fecha, 'DD/MM/YYYY HH:mm:ss', true)}
                  </React.Fragment>
                }>
                <InfoOutlinedIcon color='info' />
              </HtmlTooltip>

              <span>{formatTime(timeLeft)}</span>
            </div>
          );
        }
      }
    ];
  }
  // --------------------------------------- OCA Cerrada or Expirada -------------------------------------------------------
  else if ([EstadoOCA.cerrada, EstadoOCA.expirada].includes(estadoOca)) {
    const columns: ExtendedColumnDef<OCA>[] = [
      {
        header: 'Fecha objeción por CD Origen',
        accessorKey: 'fechaOcaObjetadaOrigen',
        columnType: 'dateTimeUTC',
        size: 250,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) => dateFormatFn(row.fechaOcaObjetadaOrigen, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Objeción por CD Origen',
        accessorKey: 'comentarioObjecionOrigen',
        columnType: 'text',
        size: 280,
        AggregatedCell: ({ row }) => row.original.comentarioObjecionOrigen,
        Cell: () => ''
      },
      {
        header: 'Archivo objeción por CD Origen',
        accessorKey: 'nombreArchivoObjecionOrigen',
        columnType: 'text',
        size: 260,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          row.original.nombreArchivoObjecionOrigen ? (
            <DownloadFile
              fileName={row.original.nombreArchivoObjecionOrigen}
              fileType='application/zip'
              buttonName='Objecion'
            />
          ) : (
            ''
          ),
        Cell: () => ''
      },
      {
        header: 'Fecha objeción por EETT',
        accessorKey: 'fechaOcaObjetadaTransportista',
        columnType: 'dateTimeUTC',
        size: 220,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) =>
          dateFormatFn(row.fechaOcaObjetadaTransportista, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Objeción por EETT',
        accessorKey: 'comentarioObjecionTransportista',
        columnType: 'text',
        size: 260,
        AggregatedCell: ({ row }) => row.original.comentarioObjecionTransportista,
        Cell: () => ''
      },
      {
        header: 'Archivo objeción por EETT',
        accessorKey: 'nombreArchivoObjecionTransportista',
        columnType: 'text',
        size: 240,
        omitExport: true,
        AggregatedCell: ({ row }) =>
          row.original.nombreArchivoObjecionTransportista ? (
            <DownloadFile
              fileName={row.original.nombreArchivoObjecionTransportista}
              fileType='application/pdf'
              buttonName='Objecion'
            />
          ) : (
            ''
          ),
        Cell: () => ''
      },
      {
        header: 'Comentario Contra Objeción por CD Destino',
        accessorKey: 'comentarioContraObjecionDestino',
        columnType: 'text',
        size: 330,
        AggregatedCell: ({ row }) => row.original.comentarioContraObjecionDestino,
        Cell: () => ''
      },
      {
        header: 'Fecha cierre',
        accessorKey: 'fechaOcaCerrada',
        columnType: 'text',
        size: 160,
        AggregatedCell: ({ row }) =>
          dateFormatFn(row.original.fechaOcaCerrada, 'DD/MM/YYYY HH:mm:ss', true),
        accessorFn: (row) => dateFormatFn(row.fechaOcaCerrada, 'DD/MM/YYYY HH:mm:ss', true),
        Cell: () => ''
      },
      {
        header: 'Comentario Administrador',
        accessorKey: 'comentarioAdministrador',
        columnType: 'text',
        size: 230,
        AggregatedCell: ({ row }) => row.original.comentarioAdministrador,
        Cell: () => ''
      },
      {
        header: 'Estado anterior',
        accessorKey: 'estadoOcaAnterior',
        columnType: 'text',
        size: 170,
        AggregatedCell: ({ row }) => row.original.estadoOcaAnterior,
        Cell: () => ''
      }
    ];
    if (estadoOca === EstadoOCA.expirada) {
      return [
        ...columns,
        {
          header: '¿Reabrir?',
          accessorKey: 'action',
          columnType: 'text',
          size: 190,
          omitExport: true,
          AggregatedCell: ({ row }) =>
            CheckAccess(TipoUsuarioValues.AdminCobros) ? (
              <ReabrirOca
                rowData={row.original as OCA}
                tipoCobro={row.original.nombreTipoDesviacion}
              />
            ) : (
              <Tooltip title='Solo los Administradores de cobro pueden reabrir una OCA'>
                <Alert icon={<BlockIcon fontSize='inherit' />} severity='error'>
                  Sin acceso
                </Alert>
              </Tooltip>
            )
        }
      ];
    }

    return columns;
  }
  // -----------------------------------------------------------------------------------------------------------------------

  return [];
};
